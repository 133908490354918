<template>
  <dash-page-new :title="$t('NotificationCenter')"
             icon="mdi-bell-outline"
             no-side>

    <template #header_action>
      <v-btn v-if="$store.state.events.recentEventsCount > 0" @click="readAll" :block="SM" dark class="noCaps" :color="wsACCENT"> {{ $t("ReadAllNotifications") }}</v-btn>
    </template>

    <template #default>
      <v-data-table
          :items="items"
          :headers="headers"
          :loading="LOADING"
          hide-default-header
          class="mt-5 wsHoverLight"
          :items-per-page="25"
          :footer-props="{ itemsPerPageOptions : [25,50,100,-1]}"
      >

        <template v-slot:header="{ props }">
          <wsTableHeader :props="props" />
        </template>

        <template v-slot:item="{item}">
          <tr @click="$router.push(businessDashLink(item.router_link))"
              :style=" !item.is_read ?  `background-color : ${wsLIGHTCARD}` : ''">

            <td width="10px" :style="`border-color: ${wsBACKGROUND}`">
              <v-avatar size="32" :color="!hover ? wsLIGHTCARD : 'white'" class="mr-2" >
                <v-icon small>{{ getEventIcon(item.type) }}</v-icon>
              </v-avatar>
            </td>
            <td class="px-0 linkHover" :style="`border-color: ${wsBACKGROUND}`">
              <router-link class="noUnderline" :style="`color : ${wsACCENT}`"  :to="businessDashLink(item.router_link)">
                <h5 :class="[{'font-weight-medium' : item.is_read}]">{{ item.text }}</h5>
              </router-link>

            </td>
            <td width="10px" class="text-no-wrap" :style="`border-color: ${wsBACKGROUND}`" >
              <wsChip :text="PARSE_TIME_STAMP(item.date_created , true)" icon="mdi-calendar"></wsChip>
            </td>

          </tr>
        </template>
      </v-data-table>
    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "DashNotificationCenter",
  data() {
    return {
      items : [],
    }
  },
  watch : {
    'EVENTS_TRIGGER.ALL'() {
      this.initPage()
    }
  },
  computed: {
    headers() {
      return [
        { value : 'type' , sortable  :false },
        { text : this.$t('Event') , value : 'text' },
        { text : this.$t('Date') , value : 'date_created' }

      ]
    }
  },
  methods : {
    ...mapActions('events',['GET_EVENTS']),

    readAll() {
      let result = this.READ_EVENTS({ type : 'all'  })
      if ( !result ) {
        return
      }
      this.items.forEach( item => {
        item.is_read = true
      })
    },

    async initPage() {
      let result = await this.GET_EVENTS()
      if ( !result || result === true ) {
        return
      }
      this.items = result
    }
  },
  async mounted() {
    await this.initPage()
  }
}
</script>

<style scoped>

</style>